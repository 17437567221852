/* eslint-disable */

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../images/logoBlue.svg"
import LoginButton, { LogoutButton } from "./LoginButton"
import FlexNav from "./FlexNav"
import tw from "tailwind.macro"
import airmasonLogoDark from "../images/logos/airmason_logo_dark.svg"

import { FetchType } from "apollo-client"

export const Logo = () => <img src={logo} alt="Air Mason Logo" />

export const HeaderWithoutLogout = () => (
  <HeaderContainer>
    <FlexNav>
      <FlexNav.Left>
        <Link to="/">
          <Logo />
        </Link>
      </FlexNav.Left>
    </FlexNav>
  </HeaderContainer>
)

const HeaderContainer = styled.div`
  padding: 43px 0 32px 0;
  margin: 0 auto;
  @media (min-width: 950px) {
    padding: 50px 0 70px 0;
    margin: 0 auto;
  }
`

const DescriptionHeader = styled.div`
  font-size: 17px;
  line-height: 20px;
  ${tw`text-black flex items-center`}
  @media (max-width: 930px) {
    margin-left: 30px;
  }
  @media (max-width: 790px) {
    margin-right: 10px;
  }
  @media (max-width: 700px) {
    display: none;
  }
`

const Header = props => {
  const { isPricingPage } = props

  return (
    <HeaderContainer className={`${props.className || ""}`}>
      <FlexNav>
        <FlexNav.Left>
          <Link to="/">
            {isPricingPage ? (
              <img
                className="max-w-none"
                src={airmasonLogoDark}
                alt="Air Mason Logo"
              />
            ) : (
              <Logo />
            )}
          </Link>
        </FlexNav.Left>
        {isPricingPage && (
          <DescriptionHeader>
            Contact Sales&nbsp;
            <a className="cursor-pointer no-underline" href="tel:+16469188673">
              <span className="text-blue-1">+1 (646) 918-8673</span>
            </a>
            , or email&nbsp;{" "}
            <a
              className="cursor-pointer no-underline"
              href="mailto:hello@airmason.com"
            >
              <span className="text-blue-1">hello@airmason.com</span>
            </a>
          </DescriptionHeader>
        )}

        <FlexNav.Right>
          <LoginButton label isPricingPage />
        </FlexNav.Right>
      </FlexNav>
    </HeaderContainer>
  )
}

export default Header
