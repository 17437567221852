import { Link } from "gatsby"
/* eslint-disable */
import React from "react"
import styled from "styled-components"

import useCurrentUser from "../hooks/useCurrentUser"
import { ButtonBlue, ButtonWhite } from "./StyledButton"
import config from "../config"

const Label = styled.span`
  display: none;
  @media only screen and (min-width: 992) {
    display: inline;
  }
`

const ButtonWhiteTransparent = styled(ButtonWhite)`
  background-color: transparent;
  color: white;
  border-color: white;
`
const ButtonTransparent = styled(ButtonWhite)`
  background-color: transparent;
  color: #0f1722;
  border: 1.5px solid rgba(103, 107, 135, 0.55);
  min-width: 110px;
  @media (max-width: 790px) {
    min-width: unset;
  }
`

const logoutRedirectHome = () =>
  window.location.pathname !== "/"
    ? (window.location.href = "/login")
    : window.location.reload()

const LoginButton = ({ label, isPricingPage, isHomePage }) => {
  const { user, logout } = useCurrentUser()
  let pathname = ""

  if (typeof window !== `undefined`) {
    pathname = window.location.pathname
  }

  return (
    <span>
      {user ? (
        user.active && pathname === "/plans" ? (
          <span>
            <ButtonWhite
              onClick={async () => {
                await logout()
                window.location.reload()
              }}
            >
              Logout
            </ButtonWhite>
            <a href={`${config.HANDBOOK_HOST}/handbooks?to=dashboard`}>
              <ButtonBlue>Go to dashboard</ButtonBlue>
            </a>
          </span>
        ) : (
          <span>
            <ButtonWhite
              onClick={async () => {
                await logout()
                logoutRedirectHome()
              }}
            >
              Logout
            </ButtonWhite>
          </span>
        )
      ) : (
        <span>
          <Label>{label && "Already have an account?"} </Label>
          <Link to="/login">
            {pathname == "/enterprise" ? (
              <ButtonWhiteTransparent>Login</ButtonWhiteTransparent>
            ) : pathname === "/plans" || isPricingPage || isHomePage ? (
              <ButtonTransparent>Login</ButtonTransparent>
            ) : (
              <ButtonWhite>Login</ButtonWhite>
            )}
          </Link>
        </span>
      )}
    </span>
  )
}

export default LoginButton
